<template>
<middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  :headerExpandedAtInit="false"
  customWidth="100%"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div>
    <b-container class="px-4" fluid>
      <b-row>

        <b-col cols="12" class="text-right pt-50 mt-1">
          <span class="d-inline-block mx-50 m-0 float-left launch-button btn-outline-secondary" @click="initPreview()">
            <p class="text-centered m-0 p-0"><b-icon icon="box-arrow-up-right" scale="1" class="mr-1 my-0"></b-icon>
            
            <b v-if="isPreviewClosed">{{ $t(`middleware.list.${i18nKey}.preview.title_off`)}}</b>
            <b v-else>{{ $t(`middleware.list.${i18nKey}.preview.title_on`)}}</b>
            </p>
          </span>              
          
          <span class="text-secondary mx-1 float-left mt-75" v-if="!isPreviewClosed"><small>{{ $t(`middleware.list.${i18nKey}.preview.info`)}}: {{launchingID}}</small></span>

          <p class="d-inline-block mx-50 my-0 mr-3 text-left code-menu-hover" :class="(currentShown == 3) ? 'text-favorite active' : 'text-secondary'" @click="goNav(3); checkVariables();">
            <b-icon icon="card-checklist" scale="1" class="mx-1 my-0"></b-icon>
            <b>{{ $t(`middleware.list.${i18nKey}.sample_variables.title`) }}</b>
          </p>
          
          <span class="mx-2"/>


          <p class="d-inline-block mx-50 m-0 text-right code-menu-hover" :class="(currentShown == 0) ? 'text-favorite active' : 'text-secondary'" @click="goNav(0)">
            <b-icon icon="code" scale="1" class="mx-1 my-0"></b-icon>
            <b>HTML</b>
          </p>
          <p class="d-inline-block mx-50 m-0 text-right code-menu-hover" :class="(currentShown == 1) ? 'text-favorite active' : 'text-secondary'" @click="goNav(1)">
            <b-icon icon="brush" scale="1" class="mx-1 my-0"></b-icon>
            <b>CSS</b>
          </p>
          <p class="d-inline-block mx-50 m-0 text-right code-menu-hover" :class="(currentShown == 2) ? 'text-favorite active' : 'text-secondary'" @click="goNav(2)">
            <b-icon icon="wrench" scale="1" class="mx-1 my-0" flip-h></b-icon>
            <b>Javascript</b>
          </p>
          </b-col> 
          <b-col cols="12" v-if="middlewareData.html" class="mb-2">
            <drop v-if="currentShown == 0" @drop="(e) => { onDrop(e, 'html')}">
              <monaco
                :ref="getID('html')"
                :key="getID('editor-html')"
                id="monaco-editor-html"
                :class="!headerExpanded ? 'editor full':' editor limited' "
                language="html"
                height="700px"
                minimap_on
                updateInterval="800"

                v-model="middlewareData.html.register_1.value"

                @editorChange="editorChange()"
                @keyup.esc="keyStroke('esc')"
              />
            </drop>

            <drop v-if="currentShown == 1" @drop="(e) => { onDrop(e, 'css')}">
              <monaco
              :ref="getID('css')"
                :key="getID('editor-css')"
                id="monaco-editor-css"
                :class="!headerExpanded ? 'editor full':' editor limited' "
                language="css"
                height="700px"
                minimap_on
                updateInterval="800"

                v-model="middlewareData.css.register_1.value"

                @editorChange="editorChange()"
              />
            </drop>

            <drop v-if="currentShown == 2" @drop="(e) => { onDrop(e, 'js')}">
              <monaco
                :ref="getID('js')"
                :key="getID('editor-js')"
                id="monaco-editor-js"
                :class="!headerExpanded ? 'editor full':' editor limited' "
                language="javascript"
                height="700px"
                minimap_on
                updateInterval="800"

                v-model="middlewareData.js.register_1.value"

                @editorChange="editorChange()"
              />
            </drop>

            <!-- VARIABLES SAMPLE NAV:  =============-->
            <div v-if="currentShown == 3" 
            :class="!headerExpanded ? 'editor full':' editor limited' " class="sample-variables"
            >
              <div class="m-3"> <!-- description: -->
                <h4 class="m-2">{{ $t(`middleware.list.${i18nKey}.sample_variables.title`) }}:</h4>
                <p>{{ $t(`middleware.list.${i18nKey}.sample_variables.description[0]`) }}<span class="text-favorite">{{ $t(`middleware.list.${i18nKey}.sample_variables.description[1]`) }}</span>{{ $t(`middleware.list.${i18nKey}.sample_variables.description[2]`) }}<span class="text-favorite">{{ $t(`middleware.list.${i18nKey}.sample_variables.description[3]`) }}</span>{{ $t(`middleware.list.${i18nKey}.sample_variables.description[4]`) }}</p>
                <p><b>{{ $t(`middleware.list.${i18nKey}.sample_variables.warning[0]`) }}</b>{{ $t(`middleware.list.${i18nKey}.sample_variables.warning[1]`) }} <span class="text-favorite">{{ $t(`middleware.list.${i18nKey}.sample_variables.warning[2]`) }}</span>{{ $t(`middleware.list.${i18nKey}.sample_variables.warning[3]`) }}<span class="text-danger">{{ $t(`middleware.list.${i18nKey}.sample_variables.warning[4]`) }} <b>{{ $t(`middleware.list.${i18nKey}.sample_variables.warning[5]`) }}</b> {{ $t(`middleware.list.${i18nKey}.sample_variables.warning[6]`)}}</span>.</p>
              </div>

              <h5 class="mx-3 my-0">{{ $t(`middleware.list.${i18nKey}.sample_variables.in_use_title`) }}:</h5>
              <div class="d-flex justify-content-between mx-2"  v-if="currentVariables.length">
                <!-- Left Column: -->
                <div class="sample-variable-container">
                  <div class="my-2 d-flex" v-for="(sample, index) in currentVariables" :key="sample">
                    <template v-if="isEven(index)">
                      <span class="mt-75  w-50 mx-1"><b>{{sample}}:</b> </span>
                      <b-form-input v-model="sampleVariables[sample]" class="text-input" :placeholder="$t(`middleware.list.${i18nKey}.sample_variables.substitute_for`)" v-on:blur="renderPreview()"/>
                    </template>
                  </div>
                </div>

                <!-- Right Column: -->
                <div class="sample-variable-container" v-if="currentVariables.length > 1">
                  <div class="my-2 d-flex" v-for="(sample, index) in currentVariables" :key="sample">
                    <template  v-if="!isEven(index)">
                      <span class="mt-75  w-50 mx-1"><b>{{sample}}:</b> </span>
                      <b-form-input v-model="sampleVariables[sample]" class="text-input" :placeholder="$t(`middleware.list.${i18nKey}.sample_variables.substitute_for`)"  v-on:blur="renderPreview()"/>
                    </template>
                  </div>
                </div>

              </div>
              <div v-else class="mx-2">
                <p class="mx-2 mt-1 text-secondary">{{ $t(`middleware.list.${i18nKey}.sample_variables.no_variables_in_use`) }}</p>
              </div>

            </div> <!-- ============================ -->

            <div class="save-button d-flex">
            
              <div class="mt-25 saving-config" @click="openSavingConfig">
                <b-icon icon="gear" scale="2" class="mx-1 mt-2" :animation="cogAnimation"/>
              </div>

              <b-button block variant="primary" class="mx-0 mt-75  float-right" @click="save" :disabled="isSaving">
                <span v-if="isSaving"><b-spinner small/><span class="d-inline-block saving-adjust-text-position">{{ $t("common.terms.saving") }}</span></span>
                <span v-else>{{ $t("common.terms.save") }}</span>
              </b-button>
            </div>
          </b-col>

          <b-col>
            <p class="mt-25">
              <small>
              <span><small></small><b>{{ $t(`middleware.list.${i18nKey}.save_options.source`) }}:</b> {{getSourceLabel(inputFileName.source)}}</span>
              <span class="ml-2"><b>{{ $t(`middleware.list.${i18nKey}.save_options.namespace`) }}:</b> {{extractInputData(inputFileName)}}</span> </small> 
            </p>     
          </b-col>
      </b-row>
    </b-container>
  
    <div class="save-config-container">
      <b-collapse :visible="sidebarSaveOpts">
          <b-col class="save-config-pannel">
          <p class="float-left m-0 mt-2 p-0"><b>{{ $t(`middleware.list.${i18nKey}.save_options.title`) }}:</b></p>
          <div class="mt-3 mx-1 d-flex justify-content-end">
            <b-icon
              icon="chevron-down"
              scale="1.5"
              class="config-close-button mt-2"
              @click="sidebarSaveOpts = false"
            />
          </div>
          
          <custom-input
            v-if="variables"
            class="mb-1 mt-2"
            id="custom-input-r1"
            :possibleValues="variables"
            v-model="inputFileName"
            advanced
          >
            <template #label>
              {{ $t(`middleware.list.${i18nKey}.save_options.namespace`) }}
              <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
              <span class="float-right text-right mx-25 text-secondary">{{ $t(`middleware.list.${i18nKey}.save_options.namespace_description`) }}</span>
            </template>
          </custom-input>

        </b-col>
      </b-collapse>
    </div>
  </div>
</middleware-sidebar-outline>
</template>

<script>

import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BCard,
	BCollapse,
  BTabs,
  BTab,
  BCardText,
} from 'bootstrap-vue';
import { v4 as uuidv4 } from 'uuid';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import CustomInput from "../CustomInput.vue";
import HelperTooltip from '@/layouts/components/HelperTooltip.vue';
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent.js'
import Drop from '@/views/pages/middleware/MiddlewareManagement/Components/Drop.vue'
import Monaco from '@/layouts/components/editor/monaco.vue';
import { setTimeout } from 'timers';
import Source from "@/custom/class/Agent/Source"


import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"

import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'


  export default {
    mixins: [MiddlewaresMixin],    
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BFormGroup,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      CustomInput,
      HelperTooltip,
      BCard,
			BCollapse,
      BTabs,
      BTab,
      Monaco,
      BCardText,
      Drop,
      MiddlewareSidebarOutline,
    },
    props: {
      i18nKey: {
        type: String,
        default: "html_editor"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        variables: null,
        variant_map: undefined,
        agentList: [],
        isSaving: false,
        launchingID: undefined,
        isPreviewClosed: true,
        middlewareType: new Middlewares().items.find(el=> el.id == 8000),
        middlewareData: {
          html: undefined,
          js: undefined,
          css: undefined
        },

				headerExpanded: false,
        sidebarSaveOpts: false,

        defaultAgentActionID: 3,
        defaultAgentBlockID: 1,

        inputFileName : {
          source: 10,
          value: ""},

        currentShown: 0,
        cogAnimation:"",
        sampleVariables: [],
        preview: undefined,
      }
    },
    computed: {
      agents: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
    },  
    mounted() {
      this.init();	
    },
    methods: {
      init() {
        this.initializeAgents();
        if(this.middlewareData.html.register_destiny.value != ''){
          this.inputFileName.value = this.middlewareData.html.register_destiny.value
        }
        
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      save(){
        if (this.inputFileName.value == ""){
          makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t(`middleware.list.${this.i18nKey}.error_undefined_save`) ,
              variant: "danger",
              icon: "CheckIcon",
            });
          this.sidebarSaveOpts = true;
          return
        }

        Object.keys(this.middlewareData).forEach((key) => {
          this.middlewareData[key].register_destiny.source = this.inputFileName.source;
          this.middlewareData[key].register_destiny.value = this.inputFileName.value + '.' + key;
        });  
        
        this.buildAgents();
      },
      buildAgents() {
        this.isSaving = true;
        this.agentList = [];

        Object.keys(this.middlewareData).forEach((key) => {
          if (this.middlewareData[key].register_1) {
            this.agentList.push(DefaultAgent.defineToAPI(this.middlewareData[key], this.middlewareID));
          }
        })
        
        this.saveAgents();
      },
      saveAgents() {
        this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.isSaving = false;
            this.$emit("saved", response.data);
          })
          .catch((error) => {
            this.isSaving = false;
          });
      },
      initializeAgents() {
        Object.keys(this.middlewareData).forEach((key) => {
          this.middlewareData[key] = MiddlewareFunctions.getValueFrom(
            {
              default: DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  source: "7",
                  value: '' 
                },
                register_2: {},
                register_destiny: {
                  source: "10",
                  value: ''
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
              source: "7",
              value: key,
              from: {
                source: "register_1",
                value: "register_destiny",
              },
              data_modifier: (el) => {
                return {
                  ...el,
                  register_destiny: {
                    value: el.register_destiny.value.split('.')[0],
                    source: el.register_destiny.source,
                  } 
                };
              },
              modifier: (el) => {
                return el.split('.')[1]
              },
              multiple: false,
              expected: {
                source: "7",
                from: "register_1",
              },
            },
            this.agents
          );
        })
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
        this.$bvModal
          .msgBoxConfirm(
            this.$t(`middleware.list.${this.i18nKey}.close_warning`)+"." ,
            {
              title: this.$t(`middleware.list.${this.i18nKey}.close`)+"?" ,
              size: "sm",
              okVariant: "danger",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          ).then((value) => {
            if (value){
              this.$root.$emit("bv::toggle::collapse", this.uuid);
            }
          })
      },
      goNav(target) {
        this.currentShown = target;
      },
      openSavingConfig(){
        this.cogAnimation = "spin"
        setTimeout(() => {
          this.cogAnimation = ""
        }, 500);
        this.sidebarSaveOpts = !this.sidebarSaveOpts;
      },
      extractInputData(data){
        if (data.value == ''){
          return this.$t(`middleware.list.${this.i18nKey}.save_options.undefined`);
        }
        return data.value
      },
      renderPreview(){
        if (!this.preview){
          return;
        }
        
        // this.preview.document.location.reload()

        let style = this.middlewareData.css.register_1.value;
        let script = this.middlewareData.js.register_1.value;
        let body = this.middlewareData.html.register_1.value;
        
        style = this.substituteSampleVariables(style)
        script = this.substituteSampleVariables(script)
        body = this.substituteSampleVariables(body)

        let html = `<!DOCTYPE html><html><head><script type='text/javascript'>window.history.pushState("object or string", "Title", "/preview-instance:://${this.launchingID}"); { ${script} }</`+`script> <style>${style}</style> </head> <body>${body}</body> </html>`;
        
        this.preview.document.write(html);
        this.preview.document.close();
        this.preview.document.title = this.$t(`middleware.list.${this.i18nKey}.preview.window_title`);
      },
      initPreview(){
        this.checkVariables();
        if (!this.preview || this.preview.closed){ //re-opens preview if it was closed
          this.isPreviewClosed = false;
          this.launchingID = uuidv4();
        
          this.preview = window.open('', '_blank');
          
          var interval = setInterval(() => {
            if(!this.preview || this.preview.closed) {
              this.isPreviewClosed = true;
              this.launchingID = undefined;
              clearInterval(interval);
            }
          }, 500);
        }
        
        this.preview.focus(); //sets browser focus on the preview tab

        this.renderPreview();
      }, 
      editorChange(){ //changes in the editor emmit this function
        this.checkVariables();
        this.renderPreview();
      },
      isEven(num){
        if (num % 2 == 0){
          return true;
        } else {
          return false;
        }
      },
      onDrop(el, target) {
        const refMonaco = this.$refs[this.getID(target)]
        refMonaco.type(el.index, refMonaco.$el);
      },
      getSourceLabel(id) {
        const source = new Source(id);
        return this.$t(source.label);
     }, 
     checkVariables() {
        this.currentVariables = [];
        
        Object.keys(this.middlewareData).forEach(codeTag => { //cicles through html, css and js
          let code = this.middlewareData[codeTag].register_1.value
          if (!code){
            code = ''
          }
          let variableList = code.match(/(?<=\{\{).+?(?=\}\})/g);
          if (!variableList){
            variableList = []
          }
          variableList.forEach(variable => {
          let add = true
          this.currentVariables.forEach(sample => {
            if (sample.key == variable){
              add = false
            }
          });

          if (add){
            this.currentVariables.push(variable);
            if(!this.sampleVariables[variable]) {
              this.sampleVariables[variable] = "";
            }
          }
          })
        });
     },
     substituteSampleVariables(code){
      if(!code){
        return '';
      }

      this.currentVariables.forEach(key => {
        code = code.replaceAll('{{'+key+'}}', this.sampleVariables[key]);
      });

      return code;
     },
    }
  }
	
</script>


<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>


<style lang="scss">

  
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .saving-adjust-text-position {
    position: relative;
  }
}
.nowrap{
  white-space: nowrap;
}
.toggle-header-bar,.toggle-header-bar:focus, .toggle-header-bar:active {
	background-color: #151925 !important;
	box-shadow:  none;
	border: none;
	border-radius: 0;
}
.toggle-header-bar:hover{
  box-shadow: 0 0 10px black !important;
}
.editor{
  background-color: #1e1e1e;
	width: 100%;	
  border-radius: 5px;
  outline: 2px solid #151925;
  transition: all 0.3s ;
}
.editor.full{
  height: 75vh !important;
} 
.editor.limited{
  height: 52.5vh !important;
}

@media (max-height: 850px){
  .editor.full{
    height: 71vh !important;
  } 
  .editor.limited{
    height: 45vh !important;
  }
}
@media (max-height: 740px){
  .editor.full{
    height: 68vh !important;
  } 
  .editor.limited{
    height: 34.5vh !important;
  }
}
@media (max-height: 650px){
  .editor.full{
    height: 60vh !important;
  } 
  .editor.limited{
    height: 32vh !important;
  }
}


.sample-variables{
  overflow: auto !important;
}

.tab-header{
  display: block !important;
  margin-top: -30px !important;
}

.save-button{
  position: absolute;
  right: 13px;
  bottom: 0px;
  width: fit-content;
  min-width: 100px;
  z-index: 2;
  border-radius: 15px 0 0 0;
  padding: 0px 10px 10px 10px;
  background-color: #1e1e1e;
  box-shadow: 0px 0px 20px 01px #1e1e1e;
}
.saving-config{
  cursor: pointer;
  border-radius: 200px;
}

.save-config-container{
  margin: 0 15px;
  position: absolute;
  left: 50px;
  bottom:45px;
}

.save-config-pannel{
  outline: 2px solid sblack;
  background-color: #283046;
  min-height: 240px;
  width: 40vw;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 10px #141414;
}
.config-close-button{
  box-sizing: content-box;
  padding: 5px 10px 10px 10px;
  cursor: pointer;
}

.code-menu-hover {
  transition: all 0.13s ease-in;
  padding: 10px 10px 10px 0px;
  border-radius: 5px 5px 0 0!important;
  cursor: pointer;
  border: 4px solid rgba(0, 0, 0, 0) !important;

  &:not(.active) {
    &:hover {
      background: #09090952;
      padding: 10px 10px 10px 0px;
      border-radius: 5px;
      border: 4px solid rgba(0, 0, 0, 0) !important;
    }
  }
&.active{
  cursor: pointer;
  border-radius: 5px 5px 0 0!important;;
  background-color: #1e1e1e !important;
  padding: 10px 10px 10px 0px;
  border: 4px solid rgba(0, 0, 0, 0) !important;
  }
}


.sample-variable-container{
  width: 48%;
  margin: 20px 15px;
  padding: 10px;
  outline: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
.sample-variable-container .text-input{
  background-color: rgba(255, 255, 255, 0.01) !important;
}

.launch-button{
  cursor: pointer;
  border-radius: 5px 5px 0 0 !important;;
  padding: 9px 5px 1px 5px;
  border: none !important;
  color: rgba(207, 157, 41, 1) ;
  border-bottom: 2px solid rgba(207, 157, 41, 1) !important;
  &:hover{
    color: #cf9c29 !important;
    border-bottom: 3px solid #cf9c29 !important;
    background-color: none !important;
    box-shadow: none !important;
  }
}

.variable-custom-input {
  font-family: monospace !important;
  width: auto !important;
  display: inline-block;
  border: none !important;
  padding: 0 !important;
  height: 23px  !important;
  box-shadow: none !important;
  min-width: 8.4px !important;

  &:focus-within {
    &::placeholder {
      color: transparent !important;
    }
  }
}



</style>